<template>
  <v-sheet color="white" elevation="1" class="pa-5">
    <h2>Learner Depart</h2>
    <v-divider class="mt-3 mb-3"></v-divider>
    <div class="text-center" v-if="isloading">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-simple-table v-if="isdeparted">
      <thead style="font-weight: 600">
        <tr>
          <th class="text-left">Departed Date</th>
          <th class="text-left">Recorded By</th>
          <th class="text-left">Reason</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="departdata">
          <td>{{ departdata.dep_date | dateReOrderRMtime }}</td>
          <td style="text-transform: capitalize">
            {{ departdata.reported_by }}
          </td>
          <td>{{ departdata.reason_txt }}</td>
        </tr>
        <tr v-else>
          <td colspan="3">No Data</td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-form class="ma-2" v-else>
      <v-row>
        <v-col md="6"
          ><v-text-field
            v-model="form.dateofdeparture"
            name="departdate"
            label="Date of depature"
            required
            type="date"
            max="3000-01-01"
            onfocus="this.max=new Date().toISOString().split('T')[0]"
            :error-messages="dateError"
            @input="$v.form.dateofdeparture.$touch()"
            @blur="$v.form.dateofdeparture.$touch()"
            messages="3 months after last contact if no response departure"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col md="6"
          ><v-select
            v-model="form.reportedby"
            :items="reportedby"
            item-text="txt"
            item-value="id"
            label="Reported By"
            required
            :error-messages="reportedbyError"
            @input="$v.form.reportedby.$touch()"
            @blur="$v.form.reportedby.$touch()"
          >
          </v-select
        ></v-col>
      </v-row>
      <v-row>
        <v-col md="6"
          ><v-select
            v-model="form.reasonid"
            :items="getResons"
            item-text="txt"
            item-value="id"
            label="Reason"
            required
            :error-messages="reasonidError"
            @input="$v.form.reasonid.$touch()"
            @blur="$v.form.reasonid.$touch()"
          >
          </v-select></v-col
      ></v-row>
      <v-row>
        <v-col md="2">
          <v-btn
            color="warning"
            block
            :disabled="isRecordBtn"
            @click="saveDeparture"
            >Record Departure</v-btn
          ></v-col
        >
      </v-row>
    </v-form>
  </v-sheet>
</template>

<script>
/*

 :error-messages="prefcartypeErrors"
            @input="$v.form.cartype.$touch()"
            @blur="$v.form.cartype.$touch()"
            messages="Standard vehicle type is the instructor's vehicle."
*/
import { required } from "vuelidate/lib/validators";
import {
  GET_DEPART_REASONS,
  M_DD,
  SET_LEARNER_DEPARTURE,
  M_OU,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "LearnerDepartForm",
  props: ["learner", "isloading", "isdeparted", "departdata"],
  data: () => ({
    reportedby: [
      { id: "school", txt: "School" },
      { id: "instructor", txt: "Instructor" },
      { id: "learner", txt: "Learner" },
      { id: "automatic", txt: "Automatic" },
    ],
    /*departReason: [
      { id: "1", txt: "Relocated to another City" },
      { id: "2", txt: "Relocated to another Country" },
      { id: "3", txt: "Passed Test Privately" },
      { id: "4", txt: "Passed Test from another Driving School" },
      { id: "5", txt: "Moved to a Private Instructor" },
      { id: "6", txt: "Moved to another Driving School" },
      {
        id: "7",
        txt: "Time Laps of 3-Months (i.e. no communication from the learner)",
      },
      { id: "8", txt: "Died" },
      { id: "9", txt: "Gave up on driving" },
    ],*/
    //isDeparted: false,
    form: {
      dateofdeparture: "",
      reportedby: "",
      reasonid: "",
    },
  }),
  validations: {
    form: {
      dateofdeparture: {
        required,
      },
      reportedby: {
        required,
      },
      reasonid: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      getResons: `${M_DD}${GET_DEPART_REASONS}`,
    }),
    dateError() {
      const errors = [];
      if (!this.$v.form.dateofdeparture.$dirty) return errors;
      !this.$v.form.dateofdeparture.required &&
        errors.push("Date of departure is required.");
      return errors;
    },
    reportedbyError() {
      const errors = [];
      if (!this.$v.form.reportedby.$dirty) return errors;
      !this.$v.form.reportedby.required &&
        errors.push("Reported by is required.");
      return errors;
    },
    reasonidError() {
      const errors = [];
      if (!this.$v.form.reasonid.$dirty) return errors;
      !this.$v.form.reasonid.required &&
        errors.push("Reason for departure is required.");
      return errors;
    },
    isRecordBtn() {
      if (this.$v.$invalid) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      saveUpdate: `${M_OU}${SET_LEARNER_DEPARTURE}`,
    }),
    saveDeparture() {
      if (this.learner) {
        console.log(this.learner);
      } else {
        console.log("No learner ID attached");
      }
      this.saveUpdate({
        learner: this.learner,
        dateofdeparture: this.form.dateofdeparture,
        reportedby: this.form.reportedby,
        reason: this.form.reasonid,
      }).then((res) => {
        if (res == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "Learner departure recorded.",
            type: "success",
            timer: 3000,
          }).then((r) => {
            console.log(r.value);
            //clear all and reloadclear()
            //this.resetForm();
            this.$router.go();
          });
        } else {
          this.$fire({
            title: "Error",
            text: "Error recording Learner Departure. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },
  },
};
/*

CREATE TABLE `learnerr_beco`.`learner_departures` (`id` INT NOT NULL AUTO_INCREMENT COMMENT 'record ID' , `learner_id` INT NOT NULL , `departed_on` DATE NOT NULL , `reported_by` VARCHAR(50) NOT NULL , `reason_id` INT NOT NULL , `created_on` DATETIME NOT NULL , PRIMARY KEY (`id`))

*/
</script>

<style scoped>
.v-progress-circular {
  margin: 2rem;
}
</style>
