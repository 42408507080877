import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
// import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";

import DashPanels from "../components/DashPanels.vue";
//learner Panel
import RegLearner from "../views/learner/RegLearner.vue";
import ManageLearners from "../views/learner/ManageLearners.vue";
import EditLearner from "../views/learner/learneredit/EditLearner.vue";
import MissingLearnerID from "../views/learner/learneredit/MissingLearnerID.vue";

import AcceptPayments from "../views/learner/AcceptPayments.vue";
import AssignInstructor from "../views/learner/AssignInstructor.vue";

//instructor Panel
import RegisterInstructor from "../views/instructors/RegisterInstructor.vue";
import ViewEditInstructors from "../views/instructors/ViewEditInstructors.vue";
//>> this one to select ADI to edit
import MissingID from "../views/instructors/edit/MissingID.vue";
import Edit from "../views/instructors/edit/Edit.vue";
import SearchInstructor from "../views/instructors/SearchInstructor.vue";
import PendingInstructors from "../views/instructors/PendingInstructors.vue";

//feedbkack Panel
import RecordLearnerFeedbacks from "../views/feedback/RecordLearnerFeedbacks.vue";
import ViewLearnerFeedback from "../views/feedback/ViewLearnerFeedback.vue";
import RecordInstructorFeedbacks from "../views/feedback/RecordInstructorFeedbacks.vue";
import ViewInstructorFeedback from "../views/feedback/ViewInstructorFeedback.vue";

//complaints Panel
import RecordComplaint from "../views/complaints/RecordComplaint.vue";
import ViewActiveComplaints from "../views/complaints/ViewActiveComplaints.vue";
import ResolvedComplaints from "../views/complaints/ResolvedComplaints.vue";

//bookings
import BookLearners from "../views/bookings/BookLearners.vue";
import BookPendingLearners from "../views/bookings/BookPendingLearners.vue";
import InstructorDiary from "../views/bookings/InstructorDiary.vue";
import RecordTestOutcome from "../views/bookings/RecordTestOutcome.vue";

//Reports
import LearnerPaymentData from "../views/reports/LearnerPaymentData.vue";
import InstructorPerformanceReport from "../views/reports/InstructorPerformanceReport.vue";
import InstructorFranchisePayData from "../views/reports/InstructorFranchisePayData.vue";

import ManagementReportsView from "../views/mangreports/ManagementReportView3.vue";
import ResultReport from "../views/mangreports/ResultReport.vue";

//Payments and transaction
import RecordInstructorFranchisePayments from "../views/transactions/RecordInstructorFranchisePayments.vue";

//admin  links
import AdminDashPanels from "../components/Admin/AdminDashPanels.vue";
import AddStaff from "../views/admin/AddStaff.vue";
import ManageStaff from "../views/admin/ManageStaff.vue";
import ResetStaffPass from "../views/admin/ResetStaffPass.vue";
import ApproveInstructor from "../views/instructors/PendingInstructors.vue";
import InstructorStateManagement from "../views/instructors/InstructorStateManagement.vue";
import AddFranchisePlan from "../views/admin/AddFranchisePlan.vue";
import ManageFranchisePlans from "../views/admin/ManageFranchisePlans.vue";
import AddLessonPlan from "../views/admin/AddLessonPlan.vue";
import ManageLessonPlans from "../views/admin/ManageLessonPlans.vue";
import ManageQuickInfo from "../views/admin/ManageQuickInfo.vue";
import UploadLeanerTC from "../views/admin/UploadLeanerTC.vue";
import UploadADITC from "../views/admin/UploadADITC.vue";
import UploadTrainigPack from "../views/admin/UploadTrainigPack.vue";
import UploadGeneralTC from "../views/admin/UploadGeneralTC.vue";

import AddLearnerQuestionGroup from "../views/admin/AddLearnerQuestionGroup.vue";
import AddInstructorQuestionGroup from "../views/admin/AddInstructorQuestionGroup.vue";
import ManageInstructorFeedbackQuestions from "../views/admin/ManageInstructorFeedbackQuestions.vue";
import ManageLearnerFeedbackQuestions from "../views/admin/ManageLearnerFeedbackQuestions.vue";

//account setup
import AccountSetup from "../components/AccountSetup.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { guest: true },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      /* {
        path: "",
        redirect: "home",
      },*/
      {
        path: "home",
        name: "Home",
        component: DashPanels,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Driving School",
        },
      },
      //learner panel
      {
        path: "registerlearner",
        name: "RegisterLearner",
        component: RegLearner,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Register Learner",
        },
      },
      {
        path: "managelearners",
        name: "ManageLearners",
        component: ManageLearners,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Manage Learners",
        },
      },
      {
        path: "editlearner",
        name: "MissingLearnerID",
        component: MissingLearnerID,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Edit Learner Error",
        },
      },
      {
        path: "editlearner/:id",
        name: "EditLearner",
        component: EditLearner,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Edit Learner",
        },
      },
      {
        path: "acceptpayments",
        name: "AcceptPayments",
        component: AcceptPayments,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Accept Learner Payments",
        },
      },
      {
        path: "assigninstructor",
        name: "AssignInstructor",
        component: AssignInstructor,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Assign Driving Instructor",
        },
      },
      //instructor Panel
      {
        path: "registerinstructor",
        name: "RegisterInstructor",
        component: RegisterInstructor,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Register Instructor",
        },
      },
      {
        path: "vieweditinstructors",
        name: "ViewEditInstructors",
        component: ViewEditInstructors,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "View/ Edit Instructors",
        },
      },
      {
        path: "edit",
        name: "MissingID",
        component: MissingID,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Edit Instructor Error",
        },
      },
      {
        path: "edit/:id",
        name: "Edit",
        component: Edit,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Edit Instructor",
        },
      },
      {
        path: "pendinginstructors",
        name: "PendingInstructors",
        component: PendingInstructors,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "View Pending Instructors",
        },
      },
      {
        path: "searchinstructor",
        name: "SearchInstructor",
        component: SearchInstructor,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Advance Search Instructor",
        },
      },
      //feedback panel
      {
        path: "recordlearnerfeedbacks",
        name: "RecordLearnerFeedbacks",
        component: RecordLearnerFeedbacks,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Record Learner Feedbacks",
        },
      },
      {
        path: "viewlearnerfeedback",
        name: "ViewLearnerFeedback",
        component: ViewLearnerFeedback,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "View Learner Feedbacks",
        },
      },
      {
        path: "recordinstructorfeedbacks",
        name: "RecordInstructorFeedbacks",
        component: RecordInstructorFeedbacks,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Record Instructor Feedbacks",
        },
      },
      {
        path: "viewinstructorfeedback",
        name: "ViewInstructorFeedback",
        component: ViewInstructorFeedback,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "View Instructor Feedbacks",
        },
      },
      //complaints panel
      {
        path: "recordcomplaint",
        name: "RecordComplaint",
        component: RecordComplaint,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Record Complaint",
        },
      },
      {
        path: "viewactivecomplaints",
        name: "ViewActiveComplaints",
        component: ViewActiveComplaints,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "View Active Complaints",
        },
      },
      {
        path: "resolvedcomplaints",
        name: "ResolvedComplaints",
        component: ResolvedComplaints,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "View Resolved Complaints",
        },
      },

      //bookings
      {
        path: "booklearners",
        name: "BookLearners",
        component: BookLearners,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Book Learners",
        },
      },
      {
        path: "bookpendinglearners",
        name: "BookPendingLearners",
        component: BookPendingLearners,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Book Non Booked Learners",
        },
      },
      {
        path: "instructordiary",
        name: "InstructorDiary",
        component: InstructorDiary,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "View Instructor Diary",
        },
      },
      {
        path: "recordtestoutcome",
        name: "RecordTestOutcome",
        component: RecordTestOutcome,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Record Test Outcomes",
        },
      },
      //Reports Panel
      {
        path: "learnerpaymentdata",
        name: "LearnerPaymentData",
        component: LearnerPaymentData,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Learner Payment Data",
        },
      },
      {
        path: "instructorperformancereport",
        name: "InstructorPerformanceReport",
        component: InstructorPerformanceReport,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Instructor Performance Report",
        },
      },
      {
        path: "instructorfranchisepaydata",
        name: "InstructorFranchisePayData",
        component: InstructorFranchisePayData,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Franchise Payments Report",
        },
      },
      {
        path: "managementreports",
        name: "ManagementReports",
        component: ManagementReportsView,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Management Reports",
        },
      },
      {
        path: "managementreports/viewreport",
        name: "ViewReport",
        component: ResultReport,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "View Report",
        },
      },

      //Payments and transactions
      {
        path: "recordinstructorfranchisepayments",
        name: "RecordInstructorFranchisePayments",
        component: RecordInstructorFranchisePayments,
        meta: {
          requiresAuth: true,
          permission: ["user"],
          title: "Record Instructor Franchise Payment",
        },
      },

      //admin routes
      {
        path: "adminhome",
        name: "AdminHome",
        component: AdminDashPanels,
        meta: { requiresAuth: true, permission: ["admin"] },
      },
      {
        path: "addstaff",
        name: "AddStaff",
        component: AddStaff,
        meta: { requiresAuth: true, permission: ["admin"] },
      },
      {
        path: "managestaff",
        name: "ManageStaff",
        component: ManageStaff,
        meta: { requiresAuth: true, permission: ["admin"] },
      },
      {
        path: "resetstaffpass",
        name: "ResetStaffPass",
        component: ResetStaffPass,
        meta: { requiresAuth: true, permission: ["admin"] },
      },
      {
        path: "approveinstructors",
        name: "ApproveInstructors",
        component: ApproveInstructor,
        meta: { requiresAuth: true, permission: ["admin"] },
      },
      {
        path: "instructorstatemanagement",
        name: "InstructorStateManagement",
        component: InstructorStateManagement,
        meta: { requiresAuth: true, permission: ["admin"] },
      },

      {
        path: "addfranchiseplan",
        name: "AddFranchisePlan",
        component: AddFranchisePlan,
        meta: { requiresAuth: true, permission: ["admin"] },
      },
      {
        path: "managefranchiseplans",
        name: "ManageFranchisePlans",
        component: ManageFranchisePlans,
        meta: { requiresAuth: true, permission: ["admin"] },
      },
      {
        path: "addlessonplan",
        name: "AddLessonPlan",
        component: AddLessonPlan,
        meta: { requiresAuth: true, permission: ["admin"] },
      },
      {
        path: "managelessonplans",
        name: "ManageLessonPlans",
        component: ManageLessonPlans,
        meta: { requiresAuth: true, permission: ["admin"] },
      },
      //feedback questions
      {
        path: "addlearnerquestiongroup",
        name: "AddLearnerQuestionGroup",
        component: AddLearnerQuestionGroup,
        meta: { requiresAuth: true, permission: ["admin"] },
      },
      {
        path: "addinstructorquestiongroup",
        name: "AddInstructorQuestionGroup",
        component: AddInstructorQuestionGroup,
        meta: { requiresAuth: true, permission: ["admin"] },
      },
      {
        path: "managelearnerfeedbackquestions",
        name: "ManageLearnerFeedbackQuestions",
        component: ManageLearnerFeedbackQuestions,
        meta: { requiresAuth: true, permission: ["admin"] },
      },
      {
        path: "manageinstructorfeedbackquestions",
        name: "ManageInstructorFeedbackQuestions",
        component: ManageInstructorFeedbackQuestions,
        meta: { requiresAuth: true, permission: ["admin"] },
      },

      {
        path: "managequickinfo",
        name: "ManageQuickInfo",
        component: ManageQuickInfo,
        meta: { requiresAuth: true, permission: ["admin"] },
      },
      {
        path: "uploadleanertc",
        name: "UploadLeanerTC",
        component: UploadLeanerTC,
        meta: { requiresAuth: true, permission: ["admin"] },
      },
      {
        path: "uploadaditc",
        name: "UploadADITC",
        component: UploadADITC,
        meta: { requiresAuth: true, permission: ["admin"] },
      },
      {
        path: "uploadtrainigpack",
        name: "UploadTrainigPack",
        component: UploadTrainigPack,
        meta: { requiresAuth: true, permission: ["admin"] },
      },
      {
        path: "uploadgeneraltc",
        name: "UploadGeneralTC",
        component: UploadGeneralTC,
        meta: { requiresAuth: true, permission: ["admin"] },
      },
    ],
  },
  {
    path: "/accountsetup",
    name: "Accountsetup",
    component: AccountSetup,
    meta: { guest: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
// move to root to log in
router.beforeEach((to, from, next) => {
  //changing page title
  document.title = to.meta.title || "Driving School System";
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["M_AUTH/isAuthenticated"]) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});
//separate admin dash functions from user function
router.beforeEach((to, from, next) => {
  if (to.meta.permission && to.meta.permission.length > 0) {
    let isAllowed = store.getters["M_AUTH/getPermissions"].some((p) =>
      to.meta.permission.includes(p)
    );

    if (!isAllowed) return next("/");
  }
  next();
});
//move to dashboard if logged in
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters["M_AUTH/isAuthenticated"]) {
      //checking is admin privilage is available
      if (
        ["admin"].some((e) =>
          store.getters["M_AUTH/getPermissions"].includes(e)
        )
      ) {
        //console.log("Move to admin view");

        next("/dashboard/adminhome");
        return;
      } else if (
        ["user"].some((e) => store.getters["M_AUTH/getPermissions"].includes(e))
      ) {
        //console.log("Move to user view");
        next("/dashboard/home");
        return;
      }
    }
    next();
  } else {
    next();
  }
});

export default router;
