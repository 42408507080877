<template>
  <v-col cols="6">
    <v-card>
      <v-card-title class="cyan text-center">
        <v-icon color="white" size="50" class="mr-3">mdi-car-hatchback</v-icon>
        <span class="text-h5 white--text text-center">Driving Test</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="testdata"
          :headers="drivingTestheaders"
          :items="testdata"
          :items-per-page="5"
          class="elevation-1 mt-3"
        ></v-data-table>
        <v-data-table
          v-else
          :headers="drivingTestheaders"
          :items="[]"
          :items-per-page="5"
          class="elevation-1 mt-3"
        ></v-data-table>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "DrivingTest",
  props: ["isloading", "testdata"],
  data() {
    return {
      drivingTestheaders: [
        {
          text: "Test ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Test Date", value: "test_date" },
        { text: "Test Time", value: "test_time" },
        { text: "Instructor", value: "adi_name" },
        { text: "Test Outcome", value: "outcome_text" },
      ],
    };
  },
};
</script>

<style></style>
