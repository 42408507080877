import Vue from "vue";

Vue.filter("mobileno", function (value) {
  if (!value || value == "0") return "N/A";

  value = value.toString();
  return value.slice(0, 4) + " " + value.slice(4, 7) + " " + value.slice(7);
});
Vue.filter("landlineno", function (value) {
  if (!value || value == "0") return "N/A";
  value = value.toString();
  return value.slice(0, 3) + " " + value.slice(3, 7) + " " + value.slice(7);
});
Vue.filter("noDec", function (value) {
  if (!value) return "";
  value = value.toString();
  //return value.slice(0, -3);
  return value.split(".")[0];
});
Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
//this one adds comma for address
Vue.filter("commaForAddr", function (value) {
  if (!value) return "";
  return `${value}, `;
});
Vue.filter("dateReOrder", function (value) {
  if (!value) return "";
  let YYYY = value.substring(0, 4);
  let MM = value.substring(5, 7);
  let DD = value.substring(8, 10);
  let time = value.substring(10);

  return DD + "-" + MM + "-" + YYYY + time;
});
Vue.filter("dateReOrderRMtime", function (value) {
  if (!value) return "";
  let YYYY = value.substring(0, 4);
  let MM = value.substring(5, 7);
  let DD = value.substring(8, 10);

  return DD + "-" + MM + "-" + YYYY;
});

Vue.filter("payReg", function (value) {
  if (!value) return "";
  let val = parseFloat(value);

  if (val == 1) {
    return "Weekly";
  } else if (val == 4.3) {
    return "Monthly";
  } else if (val == 4.33) {
    return "Monthly";
  } else if (val == 13) {
    return "Quarterly";
  } else if (val == 26) {
    return "Bi-annualy";
  } else if (val == 52) {
    return "Annualy";
  } else {
    return val;
  }
});

Vue.filter("adiStatus", function (value) {
  if (!value) return "";
  let val = parseFloat(value);
  if (val == 0) {
    return "Blocked";
  } else if (val == 1) {
    return "Active";
  } else if (val == 2) {
    return "Read Only";
  } else if (val == 3) {
    return "Newly Joined";
  } else if (val == 4) {
    return "Deleted";
  } else {
    return val;
  }
});
Vue.filter("bookingStatus", function (value) {
  if (!value) return "";
  let val = parseFloat(value);
  if (val == 0) {
    return "Blocked";
  } else if (val == 1) {
    return "Scheduled";
  } else if (val == 2) {
    return "Rescheduled";
  } else if (val == 3) {
    return "Cancelled";
  } else if (val == 4) {
    return "No show";
  } else if (val == 5) {
    return "Complete";
  } else {
    return val;
  }
});
Vue.filter("creditStatus", function (value) {
  if (!value) return "";
  let val = parseFloat(value);
  if (val == 0) {
    return "Frozen";
  } else if (val == 1) {
    return "Available";
  } else if (val == 2) {
    return "Allocated";
  } else if (val == 3) {
    return "Used";
  } else if (val == 4) {
    return "Expired";
  } else {
    return val;
  }
});
Vue.filter("plurelNotefication", function (value) {
  if (!value) return "no Notifications";
  let val = parseInt(value);
  if (val == 1) {
    return val + " notification";
  } else {
    return val + " notifications";
  }
});

Vue.filter("decoratesortcode", function (value) {
  if (!value) return "";
  value = value.toString();
  let AA = value.substring(0, 2);
  let BB = value.substring(2, 4);
  let CC = value.substring(4, 6);

  return AA + "-" + BB + "-" + CC;
});
Vue.filter("fPlanState", function (value) {
  value = value.toString();
  if (value == "1") {
    return '<span style="color: green;font-weight:600;">Active</span>';
  } else {
    return '<span style="color: red;font-weight:600;">Removed</span>';
  }
});
Vue.filter("numStyle", function (value) {
  if (!value) return "";
  return new Intl.NumberFormat("en-UK").format(value);
});
Vue.filter("currencyStyle", function (value) {
  if (!value) return "";
  return new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: "GBP",
  }).format(value);
});

//REPORT RELATED FILTERS

Vue.filter("reportinggender", function (value) {
  value = value.toString();
  if (value == "m") {
    return "Male";
  } else {
    return "Female";
  }
});
Vue.filter("sfortext", function (value) {
  value = parseInt(value);
  if (value > 1) {
    return "Learners";
  } else {
    return "Learner";
  }
});
Vue.filter("plurelText", function (value) {
  if (!value) return "";
  if (value > 1) {
    return "s";
  }
});
Vue.filter("pluralisation", function (num, txt) {
  if (!num) return txt;
  if (num > 1) {
    return txt + "s";
  } else {
    return txt;
  }
});
Vue.filter("gendarRatioName", function (value) {
  if (!value) return "";

  switch (value) {
    case "A":
      return `17 - 25`;
    case "B":
      return `26 - 35`;
    case "C":
      return `36 - 45`;
    case "D":
      return `46 - 55`;
    case "E":
      return `56 - 65`;
    case "F":
      return `65 & over`;

    default:
      return "";
  }
});
Vue.filter("precentage", function (numerator, denominator) {
  if (!denominator || !numerator) return "0%";
  return ((numerator / denominator) * 100).toFixed(2) + "%";
});
Vue.filter("getAssignState", function (value) {
  if (!value) return "";

  let rep;
  switch (value) {
    case 0:
      rep = "Blocked";
      break;
    case 1:
      rep = "Active";
      break;
    case 2:
      rep = "Re-Assigned";
      break;
    case 3:
      rep = "abandoned";
      break;
    case 4:
      rep = "Passed and Complete";
      break;
    case 5:
      rep = "No Communcation";
      break;
    case 6:
      rep = "Joined Other School";
      break;
    default:
      rep = value;
      break;
  }
  return rep;
});
