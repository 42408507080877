<template>
  <div class="row">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5 ma-5">
        <h2>Edit/Update Learner</h2>
        <v-divider></v-divider>
        <v-container fluid>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="4">
              <v-card class="mx-auto" max-width="400">
                <v-card-text>
                  <v-list-item-avatar tile size="80"
                    ><v-avatar size="70">
                      <v-icon color="green" size="60"
                        >mdi-account-school</v-icon
                      >
                    </v-avatar></v-list-item-avatar
                  >
                  <div>Learner</div>
                  <p class="text-h4 text--primary">
                    {{ learnerObj.title }} {{ learnerObj.fname }}
                    {{ learnerObj.lname }}
                  </p>
                  <p class="text-h6">
                    <v-icon class="mx-2">mdi-card-account-details</v-icon>L{{
                      learnerObj.student_id
                    }}
                  </p>
                  <p class="text-h5 text--primary">
                    <v-icon color="black" class="mx-2">mdi-phone</v-icon
                    >{{ learnerObj.mobile | mobileno }}
                  </p>
                  <p class="text-h5 text--primary">
                    <v-icon color="black" class="mx-2">mdi-at</v-icon
                    >{{ learnerObj.email }}
                  </p>
                  <p
                    class="text-h5 text--primary"
                    style="text-transform: capitalize"
                  >
                    <v-icon color="black" class="mx-2">mdi-cog</v-icon
                    >{{ learnerObj.lessons_type }}
                  </p>
                  <p class="text-h5 text--primary">
                    <v-icon color="black" class="mx-2">mdi-account-plus</v-icon
                    >{{ learnerObj.created }}
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-row align="center" justify="end">
                    <v-icon class="mr-1 mb-5" large @click="dialog = true">
                      mdi-account-edit
                    </v-icon>
                    <span class="subheading mr-5 mb-5">Edit</span>
                  </v-row>
                </v-card-actions>
              </v-card></v-col
            >
            <v-dialog v-model="dialog" max-width="960px" scrollable>
              <v-card>
                <v-card-title class="text-h5 blue lighten-2">
                  <span>View/Edit Learners</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <LearnerForm
                      v-if="passingID"
                      form-type="edit"
                      :learner="passingID"
                      :is-locked="true"
                      @saveDone="close"
                    /> </v-container></v-card-text></v-card
            ></v-dialog>
            <template v-if="adiObjs.length == 1">
              <v-col cols="4" v-for="(adi, k) in adiObjs" :key="k">
                <v-card class="mx-auto" max-width="400">
                  <v-card-text>
                    <v-list-item-avatar tile size="80"
                      ><v-avatar size="70">
                        <v-icon color="indigo" size="60"
                          >mdi-human-male-board-poll</v-icon
                        >
                      </v-avatar></v-list-item-avatar
                    >

                    <div>Driving Instructor</div>
                    <p class="text-h4 text--primary">
                      {{ adi.adi_title }} {{ adi.adi_fname }}
                      {{ adi.adi_lanme }}
                    </p>
                    <p class="text-h6">
                      <v-icon class="mx-2">mdi-card-account-details</v-icon>A{{
                        adi.adi_id
                      }}
                    </p>
                    <p class="text-h5 text--primary">
                      &nbsp;
                      <!-- <v-icon color="black" class="mx-2">mdi-phone</v-icon
                      >{{ adi.adi_id }} -->
                    </p>
                    <p class="text-h5 text--primary">
                      &nbsp;
                      <!-- <v-icon color="black" class="mx-2">mdi-at</v-icon
                      >{{ adi.adi_id }} -->
                    </p>
                    <p class="text-h5 text--primary">
                      &nbsp;
                      <!-- <v-icon color="black" class="mx-2"
                        >mdi-human-male-female</v-icon
                      >{{ adi.adi_id }}--{teaching genders} -->
                    </p>
                    <p class="text-h5 text--primary">
                      <v-icon color="black" class="mx-2"
                        >mdi-state-machine</v-icon
                      >{{ adi.assign_status | getAssignState }}
                    </p>
                    <p class="text-h5 text--primary">&nbsp;</p>
                  </v-card-text>
                </v-card></v-col
              >
            </template>
            <!-- <v-col cols="2"></v-col>  -->
          </v-row></v-container
        >

        <v-container fluid>
          <v-row dense>
            <v-col cols="3">
              <v-card class="mx-auto" color="cyan" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="mb-4">
                      <v-icon size="60">mdi-check</v-icon>
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      Lessons Completed
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-avatar tile size="105"
                    ><v-avatar color="white" size="90">
                      <span class="cyan--text text-h4">{{ totLessons }}</span>
                    </v-avatar></v-list-item-avatar
                  >
                </v-list-item>

                <v-card-actions>
                  <!-- <v-btn outlined rounded text> Button </v-btn> -->
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card class="mx-auto" color="light-green" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="mb-4">
                      <v-icon size="60">mdi-car-clock</v-icon>
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      Remaining Credits
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-avatar tile size="105"
                    ><v-avatar color="white" size="90">
                      <span class="light-green--text text-h4">{{
                        remCredits
                      }}</span>
                    </v-avatar></v-list-item-avatar
                  >
                </v-list-item>

                <v-card-actions>
                  <!-- <v-btn outlined rounded text> Button </v-btn> -->
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card class="mx-auto" color="amber" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="mb-4">
                      <v-icon size="60">mdi-star-four-points-circle</v-icon>
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      Total Credits
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-avatar tile size="105"
                    ><v-avatar color="white" size="90">
                      <span class="amber--text text-h4">{{ totCred }}</span>
                    </v-avatar></v-list-item-avatar
                  >
                </v-list-item>

                <v-card-actions>
                  <!-- <v-btn outlined rounded text> Button </v-btn> -->
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card class="mx-auto" color="deep-purple lighten-3" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="mb-4">
                      <v-icon size="60">mdi-cash-register</v-icon>
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      Total Paid
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-avatar tile size="105"
                    ><v-avatar color="white" size="100">
                      <span class="deep-purple--text text-h4">{{
                        totPaid
                      }}</span>
                    </v-avatar></v-list-item-avatar
                  >
                </v-list-item>

                <v-card-actions>
                  <!-- <v-btn outlined rounded text> Button </v-btn> -->
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-container fluid>
          <v-row>
            <Bookings
              :bookingsdata="bookingsHistoryData"
              :isloading="isBookingsHistoryLoading"
              :ishistory="isBookingsHistory"
            />
            <PayHistory
              :paydata="payHistoryData"
              :isloading="isPayHistoryLoading"
            />
          </v-row>
          <v-row>
            <TheoryTest
              :learner="passingID"
              :testdata="theoryTestData"
              :isloading="isTheoryTestLoading"
            />
            <DrivingTest
              :testdata="drivingTestData"
              :isloading="isDrivingTestLoading"
            />
          </v-row>
          <v-row>
            <EyeTest
              :learner="passingID"
              :testdata="eyetestData"
              :adis="adiObjs"
              :isloading="isEyeTestLoading"
            />
            <DepartInfo
              :learner="passingID"
              :isloading="isDepartLoading"
              :departdata="departData"
            />
          </v-row>
        </v-container>
        <!--v-sheet
          color="white"
          elevation="3"
          min-height="100"
          class="text-center pa-5 ma-5"
        >
        </v-sheet-->
      </v-sheet></v-col
    >
  </div>
</template>
<script>
import Bookings from "@/components/LearnerComps/Dash/comp/Bookings.vue";
import PayHistory from "@/components/LearnerComps/Dash/comp/PayHistory.vue";
import TheoryTest from "@/components/LearnerComps/Dash/comp/TheoryTest.vue";
import DrivingTest from "@/components/LearnerComps/Dash/comp/DrivingTest.vue";
import EyeTest from "@/components/LearnerComps/Dash/comp/EyeTest.vue";
import DepartInfo from "@/components/LearnerComps/Dash/comp/DepartInfo.vue";

import LearnerForm from "@/components/LearnerComps/LearnerForm.vue";
import { mapActions } from "vuex";
import {
  //GET_RECENT_LEARNERS,
  M_OU,
  //LOAD_RECENT_LEARNERS,
  //LOAD_ASSINGED_LEARERS,
  //GET_ASSINGED_LEARERS,
  LOAD_LEARER_DEPARTURE_INFO,
  LOAD_LEARNER_EYE_TEST,
  LOAD_LEARNER_DRIVING_TEST,
  LOAD_THEORY_TEST_INFO,
  LOAD_LEARNER_PAY_HISTORY,
  LOAD_LEARNER_BOOKINGS_HISTORY,
  GET_ALL_INSTRUCTORS,
} from "@/store/mutation-list";
import { mapGetters } from "vuex/dist/vuex.common.js";

export default {
  name: "EditLearner",
  components: {
    Bookings,
    PayHistory,
    TheoryTest,
    DrivingTest,
    EyeTest,
    DepartInfo,
    //
    LearnerForm,
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard/home",
        },
        {
          text: "View/Edit Learner",
          disabled: false,
          href: "/dashboard/managelearners",
        },
        {
          text: "Edit Learner",
          disabled: true,
          href: "/dashboard/editlearner",
        },
      ],
      passingID: null,
      learnerObj: {},
      adiObjs: [],
      adiID: null,
      adiDisplay: {},
      dialog: false,
      //eye sight test
      isEyeTestLoading: false,
      eyetestData: [],
      //leaner departure
      isDepartLoading: false,
      departData: [],
      //driving test
      isDriveingTestTaken: false,
      isDrivingTestLoading: false,
      drivingTestData: [],
      //theory test
      isTheoryTestTaken: false,
      isTheoryTestLoading: false,
      theoryTestData: [],
      //payment history
      isPayHistory: false,
      isPayHistoryLoading: false,
      payHistoryData: [],
      //bookings history
      isBookingsHistory: false,
      isBookingsHistoryLoading: false,
      bookingsHistoryData: [],
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    ...mapActions({
      loadEyeTestInfo: `${M_OU}${LOAD_LEARNER_EYE_TEST}`, //departed info will get loaded on click as well
      loadDepartureInfo: `${M_OU}${LOAD_LEARER_DEPARTURE_INFO}`, //departed info will get loaded on click as well
      loadDrivingTestInfo: `${M_OU}${LOAD_LEARNER_DRIVING_TEST}`, //departed info will get loaded on click as well
      loadTheoryTestInfo: `${M_OU}${LOAD_THEORY_TEST_INFO}`, //departed info will get loaded on click as well
      loadPaymentHistory: `${M_OU}${LOAD_LEARNER_PAY_HISTORY}`, //departed info will get loaded on click as well
      loadBookingsHistory: `${M_OU}${LOAD_LEARNER_BOOKINGS_HISTORY}`, //departed info will get loaded on click as well
      loadAllADIs: `${M_OU}${GET_ALL_INSTRUCTORS}`, //departed info will get loaded on click as well
    }),
    //this is a call to load all adis so we can get details of leaner adi
    loadAllAdis() {
      /*if (this.adiObjs) {
        this.loadAllADIs().then((rep) => {
          //this.adiDisplay = rep.find((adi) => adi.adi_id == this.adiID);

          console.log(rep);
          //console.log(this.adiDisplay);
        });
      }*/
    },
    eyeTestDataLoader() {
      //set spinners and load eye test data
      this.isEyeTestLoading = true;
      this.loadEyeTestInfo({ learner: this.passingID })
        .then((rep) => {
          if (rep.length > 0) {
            this.isEyeTested = true;
            // this.eyetestData = rep[0];
            rep.forEach((rec) => {
              this.eyetestData.push(rec);
            });
          } else {
            this.isEyeTested = false;
            this.eyetestData = null;
          }
        })
        .finally(() => {
          this.isEyeTestLoading = false;
        });
    },
    departDataLoader() {
      //set the spinner to load
      this.isDepartLoading = true;
      this.loadDepartureInfo({ learner: this.passingID })
        .then((rep) => {
          //lets check if we get an empty arrya
          if (rep.length > 0) {
            //console.log(rep);

            this.isDeparted = true;
            this.departData.push(rep[0]);
          } else {
            //console.log("Nothing inside");
            this.isDeparted = false;
            this.departData = null;
          }
        })
        .finally(() => {
          this.isDepartLoading = false;
        });
    },
    drivingTestDataLoader() {
      //set the spinner to load
      this.isDrivingTestLoading = true;
      this.loadDrivingTestInfo({ learner: this.passingID })
        .then((rep) => {
          //lets check if we get an empty arrya
          if (rep.length > 0) {
            // console.log(rep);
            this.isDriveingTestTaken = true;
            rep.forEach((rec) => {
              this.drivingTestData.push(rec);
            });
          } else {
            //console.log("Nothing inside");
            this.isDriveingTestTaken = false;
            this.drivingTestData = null;
          }
        })
        .finally(() => {
          this.isDrivingTestLoading = false;
        });
    },
    theoryTestDataLoader() {
      //set the spinner to load
      this.isTheoryTestLoading = true;
      this.loadTheoryTestInfo({ learner: this.passingID })
        .then((rep) => {
          //lets check if we get an empty arrya
          if (rep.length > 0) {
            //console.log(rep);
            this.isTheoryTestTaken = true;
            rep.forEach((rec) => {
              this.theoryTestData.push(rec);
            });
          } else {
            //console.log("Nothing inside");
            this.isTheoryTestTaken = false;
            this.theoryTestData = null;
          }
        })
        .finally(() => {
          this.isTheoryTestLoading = false;
        });
    },
    payhistoryDataLoader() {
      //set the spinner to load
      this.isPayHistoryLoading = true;
      this.loadPaymentHistory({ learner: this.passingID })
        .then((rep) => {
          //lets check if we get an empty arrya
          if (rep.length > 0) {
            //console.log(rep);
            this.isPayHistory = true;
            rep.forEach((rec) => {
              this.payHistoryData.push(rec);
            });
          } else {
            //console.log("Nothing inside");
            this.isPayHistory = false;
            this.payHistoryData = null;
          }
        })
        .finally(() => {
          this.isPayHistoryLoading = false;
        });
    },
    bookingshistoryDataLoader() {
      //set the spinner to load
      this.isBookingsHistoryLoading = true;
      this.loadBookingsHistory({ learner: this.passingID })
        .then((rep) => {
          //lets check if we get an empty arrya
          if (rep.length > 0) {
            //console.log(rep);
            this.isBookingsHistory = true;
            rep.forEach((rec) => {
              this.bookingsHistoryData.push(rec);
            });
          } else {
            //console.log("Nothing inside");
            this.isBookingsHistory = false;
            this.bookingsHistoryData = null;
          }
        })
        .finally(() => {
          this.isBookingsHistoryLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters({}),
    totPaid() {
      if (!this.payHistoryData) {
        return "£0";
      } else {
        let tot = 0;
        this.payHistoryData.forEach((ele) => {
          tot += parseFloat(ele.price);
        });
        return "£" + tot;
      }
    },
    totCred() {
      if (!this.payHistoryData) {
        return 0;
      } else {
        let tot = 0;
        this.payHistoryData.forEach((ele) => {
          tot += parseFloat(ele.credits);
        });
        return tot;
      }
    },
    totLessons() {
      if (!this.bookingsHistoryData) {
        return 0;
      } else {
        let tot = 0;
        this.bookingsHistoryData.forEach(() => {
          tot += 1;
        });
        return tot;
      }
    },
    remCredits() {
      if (!this.totCred && !this.totLessons) {
        return 0;
      } else {
        return this.totCred - this.totLessons;
      }
    },
  },
  mounted() {
    //console.log("Start to load some data here");
    //console.log(this.$route.params.id);
    //console.log(this.$route.params.info);
    if (this.$route.params.info) {
      this.learnerObj = this.$route.params.info;
    } else {
      //need to reroute to otherpage as there is no data
      this.$router.push({ name: "MissingLearnerID" }); //"/dashboard/editlearner/");
    }
    if (this.$route.params.id) {
      this.passingID = this.$route.params.id;
      this.eyeTestDataLoader();
      this.departDataLoader();
      this.drivingTestDataLoader();
      this.theoryTestDataLoader();
      this.payhistoryDataLoader();
      this.bookingshistoryDataLoader();
      this.loadAllAdis();
    }
    if (this.$route.params.adi.lst.length > 0) {
      //this.adiObjs = this.$route.params.adi.lst;
      this.adiObjs = this.$route.params.adi.lst.filter(
        (rec) => rec.assign_status === 1
      );
      this.adiID = this.adiObjs[0].adi_id;
    }
  },
};
</script>

<style scoped>
.point {
  cursor: pointer;
}
</style>
