<template>
  <v-col cols="6"
    ><v-card>
      <v-card-title class="red text-center">
        <v-icon color="white" size="50" class="mr-3">mdi-exit-run</v-icon>
        <span class="text-h5 white--text text-center">Depart Info </span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="departdata"
          :headers="departHeaders"
          :items="departdata"
          :items-per-page="5"
          class="elevation-1 mt-3"
          ><template v-slot:[`item.reported_by`]="{ item }">
            <span style="text-transform: capitalize">{{
              item.reported_by
            }}</span>
          </template></v-data-table
        >

        <v-divider></v-divider>
        <v-form class="ma-2" v-if="!departdata">
          <v-container>
            <v-divider class="mt-3 mb-5"></v-divider>
            <h3 class="mb-3">Record Learner Depature</h3>
            <!-- <h3>Record Learner Depature</h3> -->
            <!-- <v-divider class="my-3"></v-divider> -->
            <v-row>
              <v-col md="6"
                ><v-text-field
                  v-model="form.dateofdeparture"
                  name="departdate"
                  label="Date of depature"
                  required
                  type="date"
                  max="3000-01-01"
                  onfocus="this.max=new Date().toISOString().split('T')[0]"
                  :error-messages="dateError"
                  @input="$v.form.dateofdeparture.$touch()"
                  @blur="$v.form.dateofdeparture.$touch()"
                  messages="3 months after last contact if no response departure"
                ></v-text-field
              ></v-col>
              <!--/v-row>
        <v-row-->
              <v-col md="6"
                ><v-select
                  v-model="form.reportedby"
                  :items="reportedby"
                  item-text="txt"
                  item-value="id"
                  label="Reported By"
                  required
                  :error-messages="reportedbyError"
                  @input="$v.form.reportedby.$touch()"
                  @blur="$v.form.reportedby.$touch()"
                >
                </v-select
              ></v-col>
            </v-row>
            <v-row>
              <v-col md="6"
                ><v-select
                  v-model="form.reasonid"
                  :items="getResons"
                  item-text="txt"
                  item-value="id"
                  label="Reason"
                  required
                  :error-messages="reasonidError"
                  @input="$v.form.reasonid.$touch()"
                  @blur="$v.form.reasonid.$touch()"
                >
                </v-select></v-col
            ></v-row>
            <v-row>
              <v-col md="2">
                <v-btn
                  color="warning"
                  block
                  :disabled="isRecordBtn"
                  @click="saveDeparture"
                  >Record Departure</v-btn
                ></v-col
              >
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>

    <!--h3 class="text-center">
      <v-icon color="red" size="80" class="ma-2">mdi-exit-run</v-icon>Depart
      Info
    </h3>
    <v-data-table
      :headers="departHeaders"
      :items="departData"
      :items-per-page="5"
      class="elevation-1"
    ></v-data-table-->
  </v-col>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import {
  GET_DEPART_REASONS,
  M_DD,
  SET_LEARNER_DEPARTURE,
  M_OU,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "DepartInfo",
  props: ["learner", "isloading", "isdeparted", "departdata"],
  data() {
    return {
      departHeaders: [
        {
          text: "Date",
          align: "start",
          sortable: false,
          value: "dep_date",
        },
        { text: "Reported By", value: "reported_by" },
        { text: "Reason", value: "reason_txt" },
      ],
      /*departData: [
        {
          repdate: "05/01/2024",
          reportedby: "Instructor",
          reson: "Passed Test Privately",
        },
      ],*/
      reportedby: [
        { id: "school", txt: "School" },
        { id: "instructor", txt: "Instructor" },
        { id: "learner", txt: "Learner" },
        { id: "automatic", txt: "Automatic" },
      ],
      form: {
        dateofdeparture: "",
        reportedby: "",
        reasonid: "",
      },
    };
  },
  validations: {
    form: {
      dateofdeparture: {
        required,
      },
      reportedby: {
        required,
      },
      reasonid: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      getResons: `${M_DD}${GET_DEPART_REASONS}`,
    }),
    dateError() {
      const errors = [];
      if (!this.$v.form.dateofdeparture.$dirty) return errors;
      !this.$v.form.dateofdeparture.required &&
        errors.push("Date of departure is required.");
      return errors;
    },
    reportedbyError() {
      const errors = [];
      if (!this.$v.form.reportedby.$dirty) return errors;
      !this.$v.form.reportedby.required &&
        errors.push("Reported by is required.");
      return errors;
    },
    reasonidError() {
      const errors = [];
      if (!this.$v.form.reasonid.$dirty) return errors;
      !this.$v.form.reasonid.required &&
        errors.push("Reason for departure is required.");
      return errors;
    },
    isRecordBtn() {
      if (this.$v.$invalid) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      saveUpdate: `${M_OU}${SET_LEARNER_DEPARTURE}`,
    }),
    saveDeparture() {
      if (this.learner) {
        console.log(this.learner);
      } else {
        console.log("No learner ID attached");
      }
      this.saveUpdate({
        learner: this.learner,
        dateofdeparture: this.form.dateofdeparture,
        reportedby: this.form.reportedby,
        reason: this.form.reasonid,
      }).then((res) => {
        if (res == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "Learner departure recorded.",
            type: "success",
            timer: 3000,
          }).then((r) => {
            console.log(r.value);
            //clear all and reloadclear()
            //this.resetForm();
            this.$router.go();
          });
        } else {
          this.$fire({
            title: "Error",
            text: "Error recording Learner Departure. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin: 2rem;
}
</style>
