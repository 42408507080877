//loging related
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const FULL_RESET = "FULL_RESET";
export const TOKEN = "TOKEN";

export const SET_TITLES = "SET_TITLES";
export const GET_TITLES = "GET_TITLES";

export const SET_LANGUAGES = "SET_LANGUAGES";
export const GET_LANGUAGES = "GET_LANGUAGES";

export const GET_FUEL_TYPES = "GET_FUEL_TYPES";

export const GET_VEHICLE_TYPES = "GET_VEHICLE_TYPES";
export const SET_VEHICLE_TYPES = "SET_VEHICLE_TYPES";

export const GET_DATADICTIONARY_DATA = "GET_DATADICTIONARY_DATA";
export const SET_DATADICTIONARY_DATA = "SET_DATADICTIONARY_DATA";

export const GET_VEHICLE_OWNER_TYPES = "GET_VEHICLE_OWNER_TYPES";
export const SET_VEHICLE_OWNER_TYPES = "SET_VEHICLE_OWNER_TYPES";
//URLs

export const GET_ADMIN_DOCMANAGER_URL = "GET_ADMIN_DOCMANAGER_URL";

export const GET_USER_DOCMANAGER_URL = "GET_USER_DOCMANAGER_URL";
//authontications
export const IS_ADMIN = "IS_ADMIN";
//Administrator mutations
export const REGISTER_STAFF = "REGISTER_STAFF";
export const UPDATE_STAFF = "UPDATE_STAFF";
export const BLOCK_STAFF = "BLOCK_STAFF";
export const UNBLOCK_STAFF = "UNBLOCK_STAFF";
export const DELETE_STAFF = "DELETE_STAFF";
export const REST_STAFF_PASS = "REST_STAFF_PASS";
export const LOAD_ADIS_FOR_APPROVAL = "LOAD_ADIS_FOR_APPROVAL";
export const APPROVE_ADI = "APPROVE_ADI";
export const DISAPPROVE_ADI = "DISAPPROVE_ADI";

export const GET_ADIS_FOR_APPROVAL = "GET_ADIS_FOR_APPROVAL";
export const GET_ADIS_FOR_APPROVAL_LOADING = "GET_ADIS_FOR_APPROVAL_LOADING";

export const GET_STAFF_TABLE = "GET_STAFF_TABLE";
export const GET_STAFF_INFO = "GET_STAFF_INFO";

export const LOAD_FRANCHISE_PLANS = "LOAD_FRANCHISE_PLANS";
export const LOAD_LESSONS_PLANS = "LOAD_LESSONS_PLANS";

export const GET_FRANCHISE_PLANS = "GET_FRANCHISE_PLANS";
export const GET_FRANCHISE_PLANS_ACTIVE = "GET_FRANCHISE_PLANS_ACTIVE";
export const SET_FRANCHISE_PLAN = "SET_FRANCHISE_PLAN";
export const GET_FRANCHISE_PLAN = "GET_FRANCHISE_PLAN";
export const REMOVE_FRANCHISE_PLAN = "REMOVE_FRANCHISE_PLAN";
export const REMOVE_LESSON_PLAN = "REMOVE_LESSON_PLAN";

export const LOAD_QUICK_INFO = "LOAD_QUICK_INFO";
export const SET_QUICK_INFO = "SET_QUICK_INFO";

export const SET_TANDC_DOC = "SET_TANDC_DOC";

export const LOAD_LEARNER_TC = "LOAD_LEARNER_TC";
export const LOAD_ADI_TC = "LOAD_ADI_TC";
export const LOAD_PDI_TC = "LOAD_PDI_TC";
export const LOAD_TRAINIG_PACK = "LOAD_TRAINIG_PACK";

export const GET_LEARNER_TC = "GET_LEARNER_TC";
export const GET_ADI_TC = "GET_ADI_TC";
export const GET_PDI_TC = "GET_PDI_TC";
export const GET_TRAINIG_PACK = "GET_TRAINIG_PACK";

export const DOWNLOAD_TC_DOC = "DOWNLOAD_TC_DOC";

//loading spinner mutaions
export const SET_DIALOG_ACTIVE = "SET_DIALOG_ACTIVE";
export const GET_DIALOG = "GET_DIALOG";
export const SET_DIALOG_INACTIVE = "SET_DIALOG_INACTIVE";

export const SET_BACKGROUND_SYNC_ACTIVE = "SET_BACKGROUND_SYNC_ACTIVE";
export const GET_BACKGROUND_SYNC = "GET_BACKGROUND_SYNC";
export const SET_BACKGROUND_SYNC_INACTIVE = "SET_BACKGROUND_SYNC_INACTIVE";

//learner mutations
export const LOAD_RECENT_LEARNERS = "LOAD_RECENT_LEARNERS";
export const IS_RECENT_LEARNERS_LOADED = "IS_RECENT_LEARNERS_LOADED";
export const SET_RECENT_LEARNERS = "SET_RECENT_LEARNERS";
export const GET_ALL_LEARNERS = "GET_ALL_LEARNERS"; // for complaints
export const GET_RECENT_LEARNERS = "GET_RECENT_LEARNERS";
export const GET_LEARNER_BY_ID = "GET_LEARNER_BY_ID";
export const REGISTER_LEARNER = "REGISTER_LEARNER";
export const UPDATE_LEARNER = "UPDATE_LEARNER";
export const PROCESS_LEARNER_PAYMENT = "PROCESS_LEARNER_PAYMENT";
export const LOAD_ADI_PENDING_LEARNERS = "LOAD_ADI_PENDING_LEARNERS";
export const IS_ADI_PENDING_LEARNERS_LOADED = "IS_ADI_PENDING_LEARNERS_LOADED";

export const GET_ADI_PENDING_LEARNERS = "GET_ADI_PENDING_LEARNERS";
export const GET_SUITABLE_ADIS = "GET_SUITABLE_ADIS";
export const SET_LEARNER_ADI = "SET_LEARNER_ADI";

export const LOAD_ASSINGED_LEARERS = "LOAD_ASSINGED_LEARERS";
export const GET_ASSINGED_LEARERS = "GET_ASSINGED_LEARERS";
export const IS_ASSINGED_LEARERS_LOADED = "IS_ASSINGED_LEARERS_LOADED";
export const SET_LEARNER_DEPARTURE = "SET_LEARNER_DEPARTURE";
export const LOAD_LEARER_DEPARTURE_INFO = "LOAD_LEARER_DEPARTURE_INFO";

export const SET_LEARNER_EYE_TEST = "SET_LEARNER_EYE_TEST";
export const LOAD_LEARNER_EYE_TEST = "LOAD_LEARNER_EYE_TEST";

export const LOAD_LEARNER_DRIVING_TEST = "LOAD_LEARNER_DRIVING_TEST";

export const SET_THEORY_TEST = "SET_THEORY_TEST";
export const LOAD_THEORY_TEST_INFO = "LOAD_THEORY_TEST_INFO";
export const LOAD_LEARNER_PAY_HISTORY = "LOAD_LEARNER_PAY_HISTORY";
export const LOAD_LEARNER_BOOKINGS_HISTORY = "LOAD_LEARNER_BOOKINGS_HISTORY";

//instructor Mutations
export const GET_ACTIVE_INSTRUCTORS = "GET_ACTIVE_INSTRUCTORS";
// export const LOAD_PENDING_INSTRUCTORS = "LOAD_PENDING_INSTRUCTORS";
export const GET_PENDING_INSTRUCTORS = "GET_PENDING_INSTRUCTORS";
export const LOAD_ACTIVE_INSTRUCTORS = "LOAD_ACTIVE_INSTRUCTORS";
export const IS_ACTIVE_INSTRUCTORS_LOADED = "IS_ACTIVE_INSTRUCTORS_LOADED";
export const GET_ADVANCE_INSTRUCTOR_SUMMARY = "GET_ADVANCE_INSTRUCTOR_SUMMARY";
export const IS_ADVANCE_INSTRUCTOR_SUMMARY_LOADED =
  "IS_ADVANCE_INSTRUCTOR_SUMMARY_LOADED";
export const GET_ALL_INSTRUCTORS = "GET_ALL_INSTRUCTORS"; // for complaints
export const SET_ALL_INSTRUCTORS = "SET_ALL_INSTRUCTORS";

export const REGISTER_NEW_INSTRUCTOR = "REGISTER_NEW_INSTRUCTOR";

//ADI edit infomation functions
export const SAVE_ADI_BASIC_INFO = "SAVE_ADI_BASIC_INFO";
export const SAVE_ADI_ADVANCE_INFO = "SAVE_ADI_ADVANCE_INFO";
export const SAVE_ADI_NEW_CAR = "SAVE_ADI_NEW_CAR";
export const SAVE_ADI_REMOVE_CAR = "SAVE_ADI_REMOVE_CAR";
export const SAVE_ADI_CAR_DOCS = "SAVE_ADI_CAR_DOCS";
export const SAVE_ADI_CAR_CHANGES = "SAVE_ADI_CAR_CHANGES";
export const SAVE_ADI_COMPLIANCE_DOCS = "SAVE_ADI_COMPLIANCE_DOCS";
export const SAVE_ADI_COVERING_AREAS = "SAVE_ADI_COVERING_AREAS";
export const SAVE_ADI_COVERING_LANGS = "SAVE_ADI_COVERING_LANGS";
export const SAVE_ADI_TEACHING_VEHICLE_TYPES =
  "SAVE_ADI_TEACHING_VEHICLE_TYPES";
export const SAVE_FRANCHISE_CHANGE = "SAVE_FRANCHISE_CHANGE";

//Bookings related mutations
export const LOAD_ADI_SUMMARY = "LOAD_ADI_SUMMARY";
export const IS_ADI_SUMMARY_LOADED = "IS_ADI_SUMMARY_LOADED";
export const GET_ADI_SUMMARY = "GET_ADI_SUMMARY";
export const GET_ADI_LEARNERS = "GET_ADI_LEARNERS";
export const GET_ADI_CUREENT_BOOKINGS = "GET_ADI_CUREENT_BOOKINGS";
export const GET_ADI_OLD_BOOKINGS = "GET_ADI_OLD_BOOKINGS";
export const GET_LEARNER_CREDITS = "GET_LEARNER_CREDITS";
export const SET_NEW_BOOKINGS = "SET_NEW_BOOKINGS";
export const LOAD_NON_BOOKED_ADI_SUMMARY = "LOAD_NON_BOOKED_ADI_SUMMARY";
export const IS_NON_BOOKED_ADI_SUMMARY_LOADED =
  "IS_NON_BOOKED_ADI_SUMMARY_LOADED";
export const GET_NON_BOOKED_ADI_SUMMARY = "GET_NON_BOOKED_ADI_SUMMARY";
export const GET_ADI_NON_BOOKED_LEARNERS = "GET_ADI_NON_BOOKED_LEARNERS";
export const SET_BOOKIN_UPDATES = "SET_BOOKIN_UPDATES";
export const SET_TEST_OUTCOME = "SET_TEST_OUTCOME";

//feedback mutations
export const GET_PENDING_FEEDBACK_LEARNERS = "GET_PENDING_FEEDBACK_LEARNERS";
export const GET_PENDING_FEEDBACK_INSTRUCTORS =
  "GET_PENDING_FEEDBACK_INSTRUCTORS";
export const GET_FEEDBACK_LEARNER_QUESTIONS = "GET_FEEDBACK_LEARNER_QUESTIONS";
export const GET_FEEDBACK_INSTRUCTOR_QUESTIONS =
  "GET_FEEDBACK_INSTRUCTOR_QUESTIONS";
export const SAVE_LEARNER_FEEDBACK = "SAVE_LEARNER_FEEDBACK";
export const SAVE_INSTRUCTOR_FEEDBACK = "SAVE_INSTRUCTOR_FEEDBACK";

export const LOAD_LEARNER_FEEDBACK_SUMMARY = "LOAD_LEARNER_FEEDBACK_SUMMARY";
export const GET_LEARNER_FEEDBACK_SUMMARY = "GET_LEARNER_FEEDBACK_SUMMARY";
export const SET_LEARNER_FEEDBACK_SUMMARY = "SET_LEARNER_FEEDBACK_SUMMARY";
export const IS_LEARNER_FEEDBACK_SUMMARY_LOADED =
  "IS_LEARNER_FEEDBACK_SUMMARY_LOADED";

export const GET_LEARNERLIST_FOR_ADI_FEEDBACKS =
  "GET_LEARNERLIST_FOR_ADI_FEEDBACKS";

export const GET_ALL_LEARNER_FEEDBACKS = "GET_ALL_LEARNER_FEEDBACKS";

export const LOAD_INSTRUCTOR_FEEDBACK_SUMMARY =
  "LOAD_INSTRUCTOR_FEEDBACK_SUMMARY";

export const GET_INSTRUCTOR_FEEDBACK_SUMMARY =
  "GET_INSTRUCTOR_FEEDBACK_SUMMARY";
export const SET_INSTRUCTOR_FEEDBACK_SUMMARY =
  "SET_INSTRUCTOR_FEEDBACK_SUMMARY";
export const IS_INSTRUCTOR_FEEDBACK_SUMMARY_LOADED =
  "IS_INSTRUCTOR_FEEDBACK_SUMMARY_LOADED";
export const GET_ADILIST_FOR_LEARNER_FEEDBACKS =
  "GET_ADILIST_FOR_LEARNER_FEEDBACKS";
export const GET_ALL_INSTRUCTOR_FEEDBACKS = "GET_ALL_INSTRUCTOR_FEEDBACKS";

export const LOAD_ALL_LEARNER_FEEDBACK_GROUPS =
  "LOAD_ALL_LEARNER_FEEDBACK_GROUPS";
export const LOAD_ALL_INSTRUCTOR_FEEDBACK_GROUPS =
  "LOAD_ALL_INSTRUCTOR_FEEDBACK_GROUPS";
export const GET_ALL_LEARNER_FEEDBACK_GROUPS =
  "GET_ALL_LEARNER_FEEDBACK_GROUPS";
export const GET_ALL_INSTRUCTOR_FEEDBACK_GROUPS =
  "GET_ALL_INSTRUCTOR_FEEDBACK_GROUPS";

//LOAD all feedback questions
export const LOAD_ALL_LEARNER_FEEDBACK_QUESTIONS =
  "LOAD_ALL_LEARNER_FEEDBACK_QUESTIONS";
export const LOAD_ALL_INSTRUCTOR_FEEDBACK_QUESTIONS =
  "LOAD_ALL_INSTRUCTOR_FEEDBACK_QUESTIONS";

//get all feedback questions
export const GET_ALL_LEARNER_FEEDBACK_QUESTIONS =
  "GET_ALL_LEARNER_FEEDBACK_QUESTIONS";
export const GET_ALL_INSTRUCTOR_FEEDBACK_QUESTIONS =
  "GET_ALL_INSTRUCTOR_FEEDBACK_QUESTIONS";
//Save feedback questions
export const SAVE_LEARNER_FEEDBACK_QUESTION = "SAVE_LEARNER_FEEDBACK_QUESTION";
export const SAVE_INSTRUCTOR_FEEDBACK_QUESTION =
  "SAVE_INSTRUCTOR_FEEDBACK_QUESTION";

//DELETE feedback questions
export const DELETE_LEARNER_FEEDBACK_QUESTION =
  "DELETE_LEARNER_FEEDBACK_QUESTION";
export const DELETE_INSTRUCTOR_FEEDBACK_QUESTION =
  "DELETE_INSTRUCTOR_FEEDBACK_QUESTION";

export const SAVE_LEARNER_FEEDBACK_GROUP = "SAVE_LEARNER_FEEDBACK_GROUP";
export const SAVE_INSTRUCTOR_FEEDBACK_GROUP = "SAVE_INSTRUCTOR_FEEDBACK_GROUP";

//Compalint mutations
export const GET_COMPLAINT_PARTIES = "GET_COMPLAINT_PARTIES";
export const GET_ACTIVE_COMPLAINTS = "GET_ACTIVE_COMPLAINTS";
export const GET_COMPLAINT_STATUS_LIST = "GET_COMPLAINT_STATUS_LIST";
export const SAVE_NEW_COMPLAINT = "SAVE_NEW_COMPLAINT";

export const LOAD_ACTIVE_COMPLAINTS = "LOAD_ACTIVE_COMPLAINTS";
export const GET_COMPLAINT_UPDATES = "GET_COMPLAINT_UPDATES";
export const SET_COMPLAINT_UPDATE = "SET_COMPLAINT_UPDATE";
export const GET_RESOLVED_COMPLAINTS = "GET_RESOLVED_COMPLAINTS";

//export const LOAD_DEPART_REASONS = "LOAD_DEPART_REASONS";
export const GET_DEPART_REASONS = "GET_DEPART_REASONS";
export const GET_EYETEST_NEXTSTEPS = "GET_EYETEST_NEXTSTEPS";

//common data mutations
export const GET_LESSON_PLANS = "GET_LESSON_PLANS";
export const SET_LESSON_PLAN = "SET_LESSON_PLAN";
export const GET_LESSON_PLANS_ACTIVE = "GET_LESSON_PLANS_ACTIVE";
export const GET_QUICK_INFO = "GET_QUICK_INFO";

//Payments and transaction mutations
export const SAVE_NEW_TRANSACTION = "SAVE_NEW_TRANSACTION";

//notifications related data
export const LOAD_NOTIFICATION_DATA = "LOAD_NOTIFICATION_DATA";
export const IS_NOTIFICATION_LOADING = "IS_NOTIFICATION_LOADING";
export const GET_NOTIFICATION_DATA = "GET_NOTIFICATION_DATA";

//pay repots related
export const SEARCH_LEARNER = "SEARCH_LEARNER";
export const LOAD_LEARNER_CREDIT_SUMMARY = "LOAD_LEARNER_CREDIT_SUMMARY";
export const LOAD_LEARNER_PAID_CREDIT_INFO =
  "LOAD_LEARNER_ALL_PAID_CREDIT_INFO";
export const LOAD_LEARNER_USED_CREDIT_INFO =
  "LOAD_LEARNER_ALL_USED_CREDIT_INFO";
export const LOAD_LEARNER_UNUSED_CREDIT_INFO =
  "LOAD_LEARNER_ALL_UNUSED_CREDIT_INFO";
export const LOAD_LEARNER_FORFEIT_CREDIT_INFO =
  "LOAD_LEARNER_ALL_FORFEIT_CREDIT_INFO";

export const LOAD_INSTRUCTOR_LESSONS_SUMMARY =
  "LOAD_INSTRUCTOR_LESSONS_SUMMARY";
export const LOAD_ADI_NEW_LEARNER_DATA = "LOAD_ADI_NEW_LEARNER_DATA";
export const LOAD_ADI_BOOKED_LESSONS_DATA = "LOAD_ADI_BOOKED_LESSONS_DATA";
export const LOAD_ADI_RESCHEDULED_LESSONS_DATA =
  "LOAD_ADI_RESCHEDULED_LESSONS_DATA";
export const LOAD_ADI_CANCELED_LESSONS_DATA = "LOAD_ADI_CANCELED_LESSONS_DATA";
export const LOAD_ADI_TEST_OUTCOMES_DATA = "LOAD_ADI_TEST_OUTCOMES_DATA";

export const LOAD_ADI_FRANCHISE_DATA = "LOAD_ADI_FRANCHISE_DATA";
export const LOAD_ADI_FRANCHISE_PAYMENTS_DATA =
  "LOAD_ADI_FRANCHISE_PAYMENTS_DATA";

// Setting up licence
export const ACTIVATE_LICENCE = "ACTIVATE_LICENCE";
/*
This is the mutations that loads and refresh the data to state*/
export const LOAD_OFFICE_STAFF = "LOAD_OFFICE_STAFF";
export const SET_OFFICE_STAFFS = "SET_OFFICE_STAFFS";

export const LOAD_DATA_AT_LOGIN = "LOAD_DATA_AT_LOGIN";

export const LOAD_MANAGEMENT_REPORT = "LOAD_MANAGEMENT_REPORT";
export const LOAD_FEEDBACK_REPORT = "LOAD_FEEDBACK_REPORT";
export const LOAD_COMPLAINTS_REPORT = "LOAD_COMPLAINTS_REPORT";
export const LOAD_INSTUCTOR_REPORTS = "LOAD_INSTUCTOR_REPORTS";
export const LOAD_FINANCIAL_REPORTS = "LOAD_FINANCIAL_REPORTS";

export const M_OA = "M_OA/"; // module office admin
export const M_OU = "M_OU/"; // module office User
export const M_ADI = "M_ADI/"; // module adi edits
export const M_COMMON = "M_COMMON/"; // module Shared Sync
export const M_AUTH = "M_AUTH/"; // module Autharisation
export const M_DD = "M_DD/"; // module Data Dictionary
