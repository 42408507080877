<template>
  <v-col cols="6">
    <v-card>
      <v-card-title class="blue text-center">
        <v-icon color="white" size="50" class="mr-3">mdi-notebook</v-icon>
        <span class="text-h5 white--text text-center">Bookings History</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="bookingsdata"
          :headers="bookingheaders"
          :items="bookingsdata"
          :items-per-page="5"
          class="elevation-1 mt-3"
        >
          <template v-slot:[`item.id`]="{ item }">
            <span style="font-weight: 600">B{{ item.id }}</span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span style="text-transform: capitalize">{{
              item.status | bookingStatus
            }}</span>
          </template>
        </v-data-table>
        <v-data-table
          v-else
          :headers="bookingheaders"
          :items="[]"
          :items-per-page="5"
          class="elevation-1 mt-3"
        >
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "Bookings",
  props: ["learner", "isloading", "ishistory", "bookingsdata"],
  data() {
    return {
      bookingheaders: [
        {
          text: "Booking ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Lesson Date", value: "date" },
        { text: "Start Time", value: "start_time" },
        { text: "End Time", value: "end_time" },
        { text: "Booked on", value: "booked_on" },
        { text: "Status", value: "status" },
      ],
      bookingsData: [
        {
          bid: "B1560",
          ldate: "05/01/2024",
          stime: "10:45",
          etime: "11:45",
          bdate: "02/01/2024",
          status: 4.0,
        },
        {
          bid: "B1561",
          ldate: "05/01/2024",
          stime: "10:45",
          etime: "11:45",
          bdate: "02/01/2024",
          status: 4.0,
        },
        {
          bid: "B1563",
          ldate: "05/01/2024",
          stime: "10:45",
          etime: "11:45",
          bdate: "02/01/2024",
          status: 4.0,
        },
        {
          bid: "B1564",
          ldate: "05/01/2024",
          stime: "10:45",
          etime: "11:45",
          bdate: "02/01/2024",
          status: 4.0,
        },
        {
          bid: "B1565",
          ldate: "05/01/2024",
          stime: "10:45",
          etime: "11:45",
          bdate: "02/01/2024",
          status: 4.0,
        },
        {
          bid: "B1567",
          ldate: "05/01/2024",
          stime: "10:45",
          etime: "11:45",
          bdate: "02/01/2024",
          status: 4.0,
        },
      ],
    };
  },
};
</script>

<style></style>
