<template>
  <v-col cols="6">
    <v-card>
      <v-card-title class="orange text-center">
        <v-icon color="white" size="50" class="mr-3"
          >mdi-account-credit-card</v-icon
        >
        <span class="text-h5 white--text text-center">Payments History</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="paydata"
          :headers="headers"
          :items="paydata"
          :items-per-page="5"
          class="elevation-1 mt-3"
        >
          <template v-slot:[`item.id`]="{ item }">
            <span style="font-weight: 600">P{{ item.id }}</span>
          </template></v-data-table
        >
        <v-data-table
          v-else
          :headers="headers"
          :items="[]"
          :items-per-page="5"
          class="elevation-1 mt-3"
        >
        </v-data-table>
      </v-card-text>
    </v-card>

    <!--h3 class="text-center">
      <v-icon color="orange" size="80" class="ma-2"
        >mdi-account-credit-card</v-icon
      >Payments History
    </h3>
    <v-data-table
      :headers="headers"
      :items="payhistory"
      :items-per-page="5"
      class="elevation-1"
    ></v-data-table-->
  </v-col>
</template>

<script>
export default {
  name: "PayHistory",
  props: ["learner", "isloading", "ishistory", "paydata"],
  data() {
    return {
      headers: [
        {
          text: "Payment ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Lesson Credits", value: "credits" },
        { text: "Amount(£)", value: "price" },
        { text: "Paid On", value: "paid_on" },
      ],
      payhistory: [
        {
          name: "P156",
          credits: 10,
          cost: 260,
          paidon: "02/01/2024",
        },
        {
          name: "P789",
          credits: 10,
          cost: 260,
          paidon: "20/01/2024",
        },
        {
          name: "P256",
          credits: 2,
          cost: 55,
          paidon: "31/01/2024",
        },
        {
          name: "P365",
          credits: 5,
          cost: 140,
          paidon: "02/02/2024",
        },
        {
          name: "P159",
          credits: 5,
          cost: 145,
          paidon: "01/03/2024",
        },
        {
          name: "P569",
          credits: 20,
          cost: 500,
          paidon: "15/03/2024",
        },
        {
          name: "P698",
          credits: 10,
          cost: 260,
          paidon: "25/03/2024",
        },
      ],
    };
  },
};
</script>

<style></style>
