<template>
  <v-col cols="6">
    <v-card>
      <v-card-title class="green text-center">
        <v-icon color="white" size="50" class="mr-3"
          >mdi-format-list-checks</v-icon
        >
        <span class="text-h5 white--text text-center">Theory Test</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="testdata"
          :headers="theoryTestheaders"
          :items="testdata"
          :items-per-page="5"
          class="elevation-1 mt-3"
        ></v-data-table>
        <v-divider></v-divider>
        <v-form class="ma-2">
          <v-container>
            <v-divider class="mt-3 mb-5"></v-divider>
            <h3 class="mb-3">Record Theory Test Results</h3>
            <v-row>
              <v-col md="3">
                <v-text-field
                  v-model="form.dateoftest"
                  name="dateoftest"
                  label="Date of the test"
                  required
                  type="date"
                  max="3000-01-01"
                  onfocus="this.max=new Date().toISOString().split('T')[0]"
                  :error-messages="dateoftestError"
                  @input="$v.form.dateoftest.$touch()"
                  @blur="$v.form.dateoftest.$touch()"
                ></v-text-field>
              </v-col>
              <v-col md="5">
                <v-text-field
                  v-model.number="form.mcq"
                  name="mcq"
                  label="Multiple-choice questions points"
                  type="number"
                  max="50"
                  min="0"
                  messages="Pass mark 43 out of 50"
                  :error-messages="mcqError"
                  @input="$v.form.mcq.$touch()"
                  @blur="$v.form.mcq.$touch()"
                ></v-text-field>
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model.number="form.hp"
                  name="hp"
                  label="Hazard perception points"
                  type="number"
                  max="75"
                  min="0"
                  messages="Pass mark 44 out of 75"
                  :error-messages="hpError"
                  @input="$v.form.hp.$touch()"
                  @blur="$v.form.hp.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-select
                  v-model.number="form.testoutcome"
                  :items="outcome"
                  item-text="txt"
                  item-value="id"
                  label="Test outcome"
                  required
                  :error-messages="testoutcomeError"
                  @input="$v.form.testoutcome.$touch()"
                  @blur="$v.form.testoutcome.$touch()"
                ></v-select>
              </v-col>
              <v-col md="6"
                ><v-btn
                  color="warning"
                  block
                  :disabled="isRecordBtn"
                  @click="saveTheoryTest"
                >
                  Record Test</v-btn
                ></v-col
              >
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
    <!--h3 class="text-center">
      <v-icon color="green" size="80" class="ma-2"
        >mdi-format-list-checks</v-icon
      >Theory Test
    </h3>
    <v-data-table
      :headers="theoryTestheaders"
      :items="theoryTestData"
      :items-per-page="5"
      class="elevation-1"
    ></v-data-table-->
  </v-col>
</template>

<script>
import { required, between } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import {
  //  M_DD,
  SET_THEORY_TEST,
  M_OU,
} from "@/store/mutation-list";
export default {
  name: "TheoryTest",
  props: ["learner", "testdata", "isloading"],
  data() {
    return {
      theoryTestheaders: [
        {
          text: "Test ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Test Date", value: "test_date" },
        { text: "Multiple-choice questions points", value: "mcq_points" },
        { text: "Hazard perception Points", value: "hp_points" },
        { text: "Test Outcome", value: "outcome_text" },
      ],
      theoryTestData: [
        {
          testid: "B1560",
          tdate: "05/01/2024",
          mcq: 41,
          hp: 12,
          outcome: "Falied",
        },
        {
          testid: "B1561",
          tdate: "05/01/2024",
          mcq: 47,
          hp: 13,
          outcome: "Passed",
        },
      ],
      outcome: [
        { id: 1, txt: "Pass" },
        { id: 0, txt: "Fail" },
      ],
      form: {
        dateoftest: null,
        mcq: null,
        hp: null,
        testoutcome: null,
      },
    };
  },
  validations: {
    form: {
      dateoftest: { required },
      mcq: { between: between(0, 50) }, //43 pass
      hp: { between: between(0, 75) }, // 44 pass
      testoutcome: { required },
    },
  },
  computed: {
    dateoftestError() {
      const errors = [];
      if (!this.$v.form.dateoftest.$dirty) return errors;
      !this.$v.form.dateoftest.required &&
        errors.push("Date of test is required.");
      return errors;
    },
    mcqError() {
      const errors = [];
      if (!this.$v.form.mcq.$dirty) return errors;
      !this.$v.form.mcq.between &&
        errors.push("Multiple-choice score is invalied.");
      return errors;
    },
    hpError() {
      const errors = [];
      if (!this.$v.form.hp.$dirty) return errors;
      !this.$v.form.hp.between &&
        errors.push("Multiple-choice score is invalied.");
      return errors;
    },
    testoutcomeError() {
      const errors = [];
      if (!this.$v.form.testoutcome.$dirty) return errors;
      !this.$v.form.testoutcome.required &&
        errors.push("Test outcome is required.");
      return errors;
    },
    isRecordBtn() {
      if (this.$v.$invalid) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      saveUpdate: `${M_OU}${SET_THEORY_TEST}`,
    }),
    saveTheoryTest() {
      if (this.learner) {
        console.log(this.learner);
      } else {
        console.log("No learner ID attached");
        return false;
      }
      this.saveUpdate({
        learner: this.learner,
        dateoftest: this.form.dateoftest,
        mcqpoints: this.form.mcq,
        hppoints: this.form.hp,
        outcome: this.form.testoutcome,
      }).then((res) => {
        if (res == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "Learner theory test recorded.",
            type: "success",
            timer: 3000,
          }).then((r) => {
            console.log(r.value);
            //clear all and reloadclear()
            //this.resetForm();
            // this.$router.go();
          });
        } else {
          this.$fire({
            title: "Error",
            text: "Error recording Learner Theory Test. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin: 2rem;
}
</style>
