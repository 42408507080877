<template>
  <v-col cols="6"
    ><v-card>
      <v-card-title class="indigo text-center">
        <v-icon color="white" size="50" class="mr-3">mdi-eye-check</v-icon>
        <span class="text-h5 white--text text-center">Eye Test </span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="testdata"
          :headers="eyeTestheaders"
          :items="testdata"
          :items-per-page="5"
          class="elevation-1 mt-3"
          ><template v-slot:[`item.outcome`]="{ item }">
            <span style="text-transform: capitalize">{{ item.outcome }}</span>
          </template>
        </v-data-table>

        <v-divider></v-divider>

        <v-form class="ma-2">
          <v-container>
            <v-divider class="mt-3 mb-5"></v-divider>
            <h3 class="mb-3">Record Eye Test</h3>
            <v-row>
              <v-col md="6"
                ><v-text-field
                  v-model="form.dateoftest"
                  name="testdate"
                  label="Date of test"
                  required
                  type="date"
                  max="3000-01-01"
                  onfocus="this.max=new Date().toISOString().split('T')[0]"
                  :error-messages="dateError"
                  @input="$v.form.dateoftest.$touch()"
                  @blur="$v.form.dateoftest.$touch()"
                  messages="Only assiged instructor can carry out an eye test."
                ></v-text-field
              ></v-col>
              <!--/v-row>
            <v-row-->
              <v-col md="6"
                ><v-text-field
                  :value="recordingADI"
                  label="Tested By"
                  readonly
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4"
                ><v-select
                  v-model="form.outcome"
                  :items="outcome"
                  item-text="txt"
                  item-value="id"
                  label="Eye Test Outcome"
                  required
                  :error-messages="outcomeError"
                  @input="$v.form.outcome.$touch()"
                  @blur="$v.form.outcome.$touch()"
                >
                </v-select
              ></v-col>
              <!--/v-row>
            <v-row-->
              <v-col md="8"
                ><v-select
                  v-model="form.nextsteps"
                  :items="getResons"
                  item-text="txt"
                  item-value="id"
                  label="Next Steps"
                  required
                  :error-messages="nextstepsError"
                  @input="$v.form.nextsteps.$touch()"
                  @blur="$v.form.nextsteps.$touch()"
                >
                </v-select></v-col
            ></v-row>
            <v-row>
              <v-col md="2">
                <v-btn
                  color="warning"
                  block
                  :disabled="isRecordBtn"
                  @click="saveEyeTest"
                  >Record Test</v-btn
                ></v-col
              >
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
    <!--  <h3 class="text-center">
      <v-icon color="indigo" size="80" class="ma-2">mdi-eye-check</v-icon>Eye
      Test
    </h3>
    <v-data-table
      :headers="eyeTestheaders"
      :items="eyeTestData"
      :items-per-page="5"
      class="elevation-1"
    ></v-data-table> -->
  </v-col>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import {
  GET_EYETEST_NEXTSTEPS,
  M_DD,
  SET_LEARNER_EYE_TEST,
  M_OU,
} from "@/store/mutation-list";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EyeTest",
  props: ["learner", "isloading", "istested", "testdata", "adis"],
  data() {
    return {
      eyeTestheaders: [
        {
          text: "Test ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Test Date", value: "tested_on" },
        { text: "Tested By", value: "adi_name" },
        { text: "Outcome", value: "outcome" },
        { text: "Next Steps", value: "nextstep" },
      ],
      eyeTestData: [
        {
          testid: "B1560",
          tdate: "05/01/2024",
          testby: "Mr Abc Def",
          outcome: "Falied",
          nextsteps: "Contact GP",
        },
        {
          testid: "B1561",
          tdate: "07/03/2024",
          testby: "Mr Abc Def",
          outcome: "Passed",
          nextsteps: "Wear glassess when driving",
        },
      ],
      outcome: [
        { id: "pass", txt: "Pass" },
        { id: "fail", txt: "Fail" },
      ],
      form: {
        dateoftest: "",
        testedby: "",
        outcome: "",
        nextsteps: "",
      },
    };
  },
  validations: {
    form: {
      dateoftest: {
        required,
      },
      outcome: {
        required,
      },
      nextsteps: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      getResons: `${M_DD}${GET_EYETEST_NEXTSTEPS}`,
    }),
    dateError() {
      const errors = [];
      if (!this.$v.form.dateoftest.$dirty) return errors;
      !this.$v.form.dateoftest.required &&
        errors.push("Date of eye test is required.");
      return errors;
    },
    outcomeError() {
      const errors = [];
      if (!this.$v.form.outcome.$dirty) return errors;
      !this.$v.form.outcome.required &&
        errors.push("Eye test outcome is required.");
      return errors;
    },
    nextstepsError() {
      const errors = [];
      if (!this.$v.form.nextsteps.$dirty) return errors;
      !this.$v.form.nextsteps.required &&
        errors.push("Eye test next step is required.");
      return errors;
    },
    isRecordBtn() {
      if (this.$v.$invalid) {
        return true;
      }
      return false;
    },
    recordingADI() {
      if (this.adis == [] || this.adis == undefined || this.adis.length == 0) {
        return "No Active Driving Instructor";
      } else {
        if (this.adis.at(-1) != undefined) {
          let localObj = this.adis.at(-1);
          return `A${localObj.adi_id} - ${localObj.adi_title} ${localObj.adi_fname} ${localObj.adi_lanme}`;
        } else {
          return "No Active Driving Instructor";
        }
      }
    },
    recordingADIID() {
      if (this.adis == [] || this.adis == undefined || this.adis.length == 0) {
        return null;
      } else {
        if (this.adis.at(-1) != undefined) {
          return this.adis.at(-1).adi_id;
        } else {
          return null;
        }
      }
    },
  },
  methods: {
    ...mapActions({
      saveUpdate: `${M_OU}${SET_LEARNER_EYE_TEST}`,
    }),
    saveEyeTest() {
      this.form.testedby = this.recordingADIID;
      if (this.learner) {
        console.log(this.learner);
      } else {
        console.log("No learner ID attached");
      }
      this.saveUpdate({
        learner: this.learner,
        dateoftest: this.form.dateoftest,
        outcome: this.form.outcome,
        testedby: this.form.testedby,
        nextsteps: this.form.nextsteps,
      }).then((res) => {
        if (res == "SUCCESS") {
          this.$fire({
            title: "Success",
            text: "Learner eye test recorded.",
            type: "success",
            timer: 3000,
          }).then((r) => {
            console.log(r.value);
            //clear all and reloadclear()
            //this.resetForm();
            //this.$router.go();
          });
        } else {
          this.$fire({
            title: "Error",
            text: "Error recording Learner Eye Test. Please try again.",
            type: "error",
            allowOutsideClick: false,
            //timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin: 2rem;
}
</style>
